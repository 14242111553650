<template>
  <div class="pa-2 mx-auto">

      <p class="text-capitalize font-weight-medium h2 pa-8 text-center"
            >
             Frequently Asked Questions
            </p> 

      <div class="pa-2 mx-auto font-weight-thin">
         <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: What do the buttons mean?
            </p>

            <p
              
              class=" text-left"
            >Answer: </p> 
             <p>If an watch has the icon "<v-icon small > mdi-check-all </v-icon>", its price has been estimated. Thus, as a buyer you can be more confident at the price. This estimation price can not be changed by the seller. Before posting the watch to the experts, the seller has to decide what additional shipping costs they want to charge. If there are additional costs (for shipping), the "<v-icon small > mdi-package-variant-closed </v-icon>" icon is desplayed. The cheapest available option is always displayed at the buy section of the app. 

               If there are no additonal costs => <span >
            <v-chip
              class="ma-1 pl-0 caption"
              
              color="primary lighten-1"
              small
            >
              <v-chip label dark color="primary">
                {{ "100" }}<v-icon right small>$vuetify.icons.custom</v-icon> </v-chip
              >
              <v-icon right> mdi-check-all </v-icon>
            </v-chip></span
          >
            . With additional costs: <span >
            <v-chip
              class="ma-1 pl-0 caption"
              
              color="primary lighten-1"
              small
            >
              <v-chip label dark color="primary">
                {{ "100" }}<v-icon right small>$vuetify.icons.custom</v-icon> </v-chip
              >
              <v-icon right> mdi-check-all </v-icon>
              <v-icon small right> mdi-plus </v-icon
              ><v-icon small right> mdi-package-variant-closed </v-icon>
            </v-chip>   
            
           </span
          >.
            </p>
            <p>If an watch has the icon "
              <v-icon small > mdi-repeat </v-icon>", it has been reposted. Its price has been deterimined previously by estimators and the watch was bought by a buyer before. The seller is not the creator of the watch. The value of the watch can have appreciated or depreciated in the meantime, therefore it is up to the seller to give a discount "<v-icon small > mdi-brightness-percent</v-icon>" or add shipping cost "<v-icon small  > mdi-package-variant-closed </v-icon>". This can also be a combination of the two, for example:
            </p><span class="pa-2 font-weight-regular text-left" >
   <v-chip small dark color="primary" class="pr-0" 
              >
              <v-hover v-slot="{ hover }" close-delay="300" open-delay="60" >
              <span>
              <span  class="pr-2 " v-if="hover" > Buy Now </span><span class="pr-2" v-else>100<v-icon right small>$vuetify.icons.custom</v-icon> </span>
              </span>
</v-hover> 


                  <v-chip label
           
            class="pl-0 caption"
            color="primary lighten-2"
            
          ><v-icon small  right> mdi-repeat </v-icon
            ><v-icon small  right> mdi-plus </v-icon
            ><v-icon small  right> mdi-package-variant-closed </v-icon>
            <v-icon small right> mdi-minus </v-icon
            ><v-icon small right> mdi-brightness-percent</v-icon>
          </v-chip>
          </v-chip
            >

          </span>
            
            
            <v-divider class="ma-6" />
             </div>
           

           
   <div class="pa-2 mx-auto font-weight-thin">
         <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: How do buy an watch?
            </p><p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p>  <p> Provide prepayment using the buttons at the details page of a watch. If you provided prepayment for an watch that has shipping cost, the seller will complete the watch transfe. Once they indicate the watch has shipped, the payment goes to the seller. Else, If you choose to provide prepayment for a watch that has local pickup, you complete the watch transfer (under Account) when you are meeting up with the seller (or at anytime to transfer the digital ownership of the watch).
<v-divider class="ma-6" />
                  <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: Does selling cost anything?
            </p><p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> 
            <p> Selling any watch is free at any time. No costs never nada. </p>
<v-divider class="ma-6" />
            <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: How do I contact a buyer/seller?
            </p>
<p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> 
            <p> Send a message to the seller at the details page of the watch. As a seller, you can message the buyer under the info of an item under Account. Alternatively, in messages you can add any address signed up to the marketplace. You may request to chat on a different chat app like Telegram or Whatsapp.
<v-divider class="ma-6" />
  <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: What are the transfer options?
            </p> 

            <p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> <p> There are 2 carefully designed options for you to choose, Pickup and Shipping. These processes differ in terms of responsibility. For pickup, the buyer is fully in control of the prepayment up until the (physical) transaction, meaning that the buyer controls when the payment is payed out to the seller. For shipping, the seller will have control of the payment. When the seller indicates the watch has been shipped, the tokens will be payed out to the seller. Up until that, the buyer may withdrawl at any moment.
       </p><p> You can access to these functions at the watch actions under Account.</p>

       <v-divider class="ma-6" />
  <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: Why should I use TRST over than Ebay Authenticity Service?
            </p> 

            <p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> <p> Buying watches online is tricky as there are many fakes out there. You can still be scammed by buying on Ebay using authenticity service  (https://www.youtube.com/watch?v=5vzUXYKQuxU). It is just a check whether the watch matches the listing. By linking the watch to the TRST blockchain, TRST provides a visual inspection for anyone to see. Ebay adds high fees and TRST does not add any fees. On TRST the watch is visually inspected and estimated before placement.  If your are buying a high value watch, it is still important to do research yourself (https://www.youtube.com/watch?v=nQJjLQitUlA). It is advisable to look up differences between the watch you want to buy and the replicas. Estimators try to spot this but ultimately as you buy the watch, it is your responsability. Still unsure if the watch is real? Ask for more information from the seller. 
       </p>
       <v-divider class="ma-6" />
  <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: Does the TRST token hold a certain value?
            </p> 

            <p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> <p>If the TRST token appreciates in value, selling watches becomes very attractive. Thus, there are more sellers willing to sell watches. Hence, the marketplace is filled watches.

If the TRST token depreciates in value, buying watches becomes very attractive. Thus, there are more buyers willing to buy watches. Hence, the marketplace is empty.

In a bull market there will be lots of sellers and in a bear market there will be lots of buyers. Like yin-yang. Alternatively, these trends can be defined as market cycles, which is a healthy principle in any economy. <!--It is up to the estimators to estimate watch prices in such a way that there are enough buyers ans sellers at any given time. Therefore, estimators are the backbone of the TRST economy.</p>-->
   
       <v-divider class="ma-6" />
  <p
              
              class="font-weight-medium pa-2 text-left"
            >
              Question: Does buying watches come with a high risk?
            </p> 

            <p
              
              class="pa-2 font-weight-light text-left"
            >Answer: </p> <p>   Buying watches online is tricky as there are many fakes out there.  On any marketplace, there are risks. Therefore, it is important to do due dilligence. With TRST, you have insight to the seller's previously sold watchs and buyer feedback. Plus, if you want to deep dive, you can view transaction histories of previous buyers. With TRST you are assured of a good price for the watch, saving you time. Also, estimators provide useful comments, and even provide information about what may be wrong with the watch. Your risk of getting a bad deal is reduced, but doing due dilligence yourself stays important. For a high value watch, It is advisable to look up the differences between the watch you want to buy and the replicas. </p>
            </div>
          <v-row>
           
       

        
        </v-row>
    
  

  </div>
</template>

<script>
import { databaseRef } from "./firebase/db";


export default {
 /// props: ["watchid"],
  //components: { ItemListBuy, WalletCoins },
  data() {
    return {
      //watchid: this.watch.id,
      //make sure deposit is number+token before sending tx
      amount: "",
      flight: false,
      flightLP: false,
      flightSP: false,
      showinfo: false,
      imageurl: "",
      loadingwatch: true,
      photos: [],
    };
  },

  mounted() {
    this.loadingwatch = true;
    const id = this.watchid;

    const imageRef = databaseRef.ref("ItemPhotoGallery/" + id + "/photos/");
    imageRef.on("value", (snapshot) => {
      const data = snapshot.val();

      if (data != null) {
        //console.log(data.photo);
        this.imageurl = data[0];
        this.loadingwatch = false;
      }
    });
    this.loadingwatch = false;
  },
  computed: {
    thiswatch() {
      return this.$store.getters.getItemByID(this.watchid);
    },

    hasAddress() {
      return !!this.$store.state.account.address;
    },
    valid() {
      return this.amount.trim().length > 0;
    },
    commentlist() {
      return this.thiswatch.comments.filter((i) => i != "") || [];
    },
  },

  methods: {
    getItemPhotos() {
      if (this.showinfo && this.imageurl != "") {
        this.loadingwatch = true;
        const id = this.watchid;

        const imageRef = databaseRef.ref("ItemPhotoGallery/" + id + "/photos/");
        imageRef.on("value", (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            this.photos = data;
            this.loadingwatch = false;
          }
        });
        this.loadingwatch = false;
      }
    },
    clickOutside() {
      if ((this.showinfo = true)) {
        this.showinfo = false;
      }
    },
  },
};
</script>



<!---
shows watch id from buy list
<div id="watch-list-buy">
      {{ watchid }}
    </div>
    ---->